.home-main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  scroll-snap-align: start;
  background-image: linear-gradient(to bottom, #ffffff, #e7e7e7cc);
  justify-content: space-around;
}

.home-header {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1%;
}

.home-header-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
}

.home-header-nav-items {
  border: none;
  background-color: transparent;
  color: #000;
  font-family: Kodchasan;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.home-header-nav-items:hover {
  color: #0019f9;
  scale: 1.1;
  transition: 0.3s;
  background-color: transparent;
}

.home-header-external {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .home-header-external-items {
    display: none;
  }
}
.home-header-external-items {
  border: none;
  background-color: transparent;
  margin: 0 20px;
  padding: 4px;
}

.home-header-external-items:hover {
  scale: 1.1;
  transition: 0.3s;
  background-color: rgba(221, 221, 221, 0.384);
  border-radius: 50%;
}

/*Disable hover animation for header name*/
#home-header-name {
  color: #0e00b1;
  font-family: Kodchasan;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  pointer-events: none;
}

@media screen and (min-width: 800px) {
  .home-body {
    flex: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .home-body-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 60%;
    margin-right: 25%;
    margin-bottom: 10%;
    z-index: 1;
  }
}
@media screen and (max-width: 800px) {
  .home-body {
    width: 100vw;
    height: 100%;
  }
  .home-body-content {
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-right: 5%;
    margin-bottom: 10%;
    margin-left: 5%;
    z-index: 1;
  }
}

#home-body-name {
  align-self: flex-start;
  color: #0e00b1;
  font-family: Kodchasan;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media screen and (max-width: 800px) {
    font-size: 1.3rem;
    margin-top: 30%;
  }
  @media screen and (min-width: 800px) {
    margin-bottom: 1;
  }
}

#home-body-title {
  align-self: flex-start;
  color: #000;
  font-family: Kodchasan;
  @media screen and (max-width: 800px) {
    font-size: 1.8rem;
    margin-top: 5%;
  }
  @media screen and (min-width: 800px) {
    font-size: 3rem;
  }
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

#home-body-description {
  text-align: start;
  color: #000;
  font-family: Kodchasan;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media screen and (max-width: 800px) {
    margin-top: 5%;
  }
}

.highlight-text {
  color: #0019f9;
  font-family: Kodchasan;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media screen and (min-width: 1024px) {
  .home-img-me {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
}
@media screen and (max-width: 1024px) {
  #home-img-me-outline {
    display: none;
  }
  .home-img-me img {
    opacity: 0.3;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
}

.projects-container {
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
  overflow: hidden;
}

.project-blur {
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(5px);
  background-color: #00000034;
  padding: 40px;
  z-index: 99;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  .project-main {
    z-index: 2;
    min-height: 80vh;
    width: 80vw;
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }
}
@media screen and (min-width: 800px) {
  .project-main {
    z-index: 2;
    min-height: 80vh;
    width: 80vw;
    display: grid;
    padding: 10px;
    grid-template-columns: repeat(3, 0.5fr);
    grid-template-rows: repeat(3, 0.5fr);
    align-items: center;
    justify-items: center;
    row-gap: 40px;
    overflow: hidden;
  }
}

#project-tile-section {
  display: flex;
}
#project-tile-section h1 {
  font-size: 2rem;
  padding: 0;
  margin: 0;
  color: #ffffff;
}

#project-tile-section h2 {
  padding: 0;
  margin: 0;
  font-size: 1.5rem;
}

#project-tile-section h3 {
  color: #ffffff;
  font-family: Kodchasan;
}

.project-tile {
  position: relative;
  @media screen and (max-width: 800px) {
    width: 100%;
    height: 25vh;
    margin: 2%;
    padding: 0%;
  }
  @media screen and (min-width: 800px) {
    height: 25vh;
    width: 22vw;

    margin: 0px;
    padding: 0px;
  }
  background-color: rgba(0, 0, 0, 0);
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.3s ease-in;
}

.project-tile.active:hover .project-tile-bg {
  background-color: rgba(0, 0, 0, 0.63);
  border-radius: 2rem;
  transition: 0.3s ease-in;
}

.project-tile.active:hover .project-tile-title {
  opacity: 1;
  transition: 0.3s ease-in;
}

.project-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 2rem;
}

.project-decorator {
  display: none;
  position: absolute;
  height: 100%;
  width: 100vw;
  z-index: 0;
  left: 0;
  top: 0;
  /* create a triangle */
  background-color: rgba(0, 0, 0, 0.219);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.project-tile-title {
  position: absolute;
  bottom: 10%;
  right: 10%;
  color: #ffffff;
  font-family: Kodchasan;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
  opacity: 0;
}

.project-tile-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  left: 0;
  top: 0;
  /* create a triangle */
  background-color: rgba(0, 0, 0, 0);
  border-radius: 200px;
}

/* hovering tile*/

.project-tile-hovered {
  position: fixed;
  top: 50%; /* Place the top of the element at the vertical center of its container */
  left: 50%; /* Place the left side of the element at the horizontal center of its container */
  transform: translate(-50%, -50%) scale(0); /* Center the element both vertically and horizontally, and start with scale(0) for initial hidden state */
  display: flex;
  @media screen and (max-width: 800px) {
    height: 92vh;
    width: 95vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: 800px) {
    height: 85vh;
    width: 80vw;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  background-color: rgba(0, 0, 0, 0.808);
  border-radius: 2rem;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 2;
}

.project-tile-hovered.active {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1); /* Show the element and center it */
}

@media screen and (max-width: 800px) {
  .project-tile-hovered-img-container {
    width: 100%;
    padding-top: 140%;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
  }
}

@media screen and (min-width: 800px) {
  .project-tile-hovered-img-container {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.project-tile-hovered-img {
  @media screen and (max-width: 800px) {
    width: 85%;
    object-fit: cover;
  }
  @media screen and (min-width: 800px) {
    height: 30%;
    width: 75%;
  }
  object-fit: cover;
  border-radius: 1.8rem;
  padding: 1rem;
}
.project-tile-hovered-content {
  color: white;
  padding: 1rem;
}

.project-tile-hovered-content h1 {
  font-family: Kodchasan;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

.project-tile-hovered-content h3 {
  font-family: Kodchasan;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

.close-button {
  position: absolute;
  top: 5%;
  right: 10%;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  font-style: italic;
  font-weight: 700;
}

@media screen and (max-width: 800px) {
  .project-main {
    margin-bottom: 10%;
    overflow-y: scroll;
  }
  /* hide scrollbar for Chrome, Safari and Opera */
  .project-main::-webkit-scrollbar {
    display: none;
  }
  .project-tile-bg {
    background-color: rgba(0, 0, 0, 0.507);
    border-radius: 2rem;
  }
  .project-tile-title {
    opacity: 1;
  }
}

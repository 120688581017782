@media screen and (max-width: 1920px) {
  .background {
    background-image: url("../../../public/assets/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }
}

@media screen and (max-width: 800px) {
  .background {
    background-image: url("../../../public/assets/bg-2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }
}

.skills-main {
  height: 100vh;
  width: 100vw;
  scroll-snap-align: start;
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skills-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
}

h1 {
  color: #000;
  font-family: Kodchasan;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#highlight-text {
  color: #117ebc;
  font-family: Kodchasan;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media screen and (max-width: 1920px) {
  .skills-container {
    flex: 2;
    width: 90vw;
    height: 80vh;
    flex-shrink: 0;
    border-radius: 22px;
    /* background: rgba(204, 204, 204, 0.63); */
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 10px;
    /* backdrop-filter: blur(4px);
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.25); */
  }
}

@media screen and (max-width: 800px) {
  .skills-container {
    overflow-y: scroll;
    flex: 2;
    width: 90vw;
    height: 80vh;
    flex-shrink: 0;
    border-radius: 22px;
    /* background: rgba(204, 204, 204, 0.63); */
    display: flex;
    flex-direction: column;
    padding: 10px;
    /* backdrop-filter: blur(4px);
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.25); */
  }
  .skills-container::-webkit-scrollbar {
    display: none;
  }
}
.skills-items {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  @media screen and (min-width: 800px) {
    align-items: center;
  }
  padding: 14px;
  /* border: 5px solid rgb(255, 255, 255); */
}

.skills-common-heading {
  color: rgb(0, 2, 90);
  font-family: Kodchasan;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skills-section-images {
  width: 45px;
  height: 45px;
  margin-right: 10px;
}

.common-content {
  color: rgb(0, 0, 0);
  font-family: Kodchasan;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media screen and (max-width: 1920px) {
  .split-column {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
}
@media screen and (max-width: 800px) {
  .split-column {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.bullet-list {
  text-align: start;
}

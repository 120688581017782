.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.make-scrollable {
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
}

.make-scrollable::-webkit-scrollbar {
  display: none;
}

.project-contact-container {
  background-image: url("../public/assets/project_bg.jpg");
  background-size: cover;
}

.side-decor {
  position: absolute;
  height: 100vh;
  width: 0.5vw;
  /* rainbow */
  background: linear-gradient(
    180deg,
    rgb(0, 217, 255) 0%,
    #ffff00 28.57%,
    #00ff00 42.86%,
    #8f00ff 65.71%,
    #754545 80%,
    white 100%
  );
}

.contact-main {
  height: 100vh !important;
  scroll-snap-align: start;
  backdrop-filter: blur(5px);
  background-color: #00000034;
  padding: 2rem;
  @media screen and (max-width: 800px) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  z-index: 99;
  overflow-y: scroll;
}

.contact-main::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 800px) {
  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 800px) {
  .contact-container {
    display: flex;
    flex-direction: row;
  }
}

.contact-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70vw;
  z-index: 99;
}

.contact-right {
  display: flex;
  @media screen and (max-width: 800px) {
    flex-direction: row;
  }
  @media screen and (min-width: 800px) {
    flex-direction: column;
  }
  justify-content: center;
  align-items: center;
  width: 30vw;
  z-index: 99;
}

.contact-icons svg {
  width: 4rem;
  height: 4rem;
  padding: 1rem;
}

.contact-icons svg:hover {
  scale: 1.1;
  transition: 0.3s;
}
.separator {
  height: 70vh;
  width: 10px;
  background-color: #d3d2d2;
  margin: 20px 0;
  z-index: 99;
}

.form-group {
  margin-bottom: 20px;
}

input[type="text"],
textarea {
  width: 40vw;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 5px;
}

textarea {
  height: 150px;
  resize: none;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4285f4;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2f5aa0;
}

.about-me-qna h3 {
  font-family: "Kodchasan", sans-serif;
  font-size: 2rem;
  font-weight: 900;
  color: #5ffaff;
  margin-bottom: 20px;
  z-index: 999;
}

.about-me-qna p {
  font-family: "Kodchasan", sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  color: #ffffff;
  margin-bottom: 20px;
  z-index: 999;
}

#about-me-title {
  font-family: "Kodchasan", sans-serif;
  font-size: 2rem;
  font-weight: 900;
  color: #ffffff;
  margin-bottom: 20px;
  font-style: italic;
  z-index: 999;
}

#contact-options {
  font-family: "Kodchasan", sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  color: #ffffff;
  margin-bottom: 20px;
  font-style: italic;
  z-index: 999;
}
